import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import { MarkdownContent } from '../components/Content';

export const Image = ({ image, title, description }) => (
  <div className="col-lg-3 col-md-4 col-xs-6">
    <a
      href={image.publicURL}
      className="d-block mb-4 h-100"
      data-toggle="lightbox"
      data-gallery="rooms"
      data-type="image"
      data-title={title}
      data-footer={description}
    >
      <Img
        fixed={image.childImageSharp.fixed}
        alt={title}
        className="img-fluid img-thumbnail"
      />
    </a>
  </div>
);

Image.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export class RoomsPageTemplate extends Component {
  componentDidMount() {
    $('.rooms__gallery').on('click', '[data-toggle="lightbox"]', function(
      event,
    ) {
      event.preventDefault();
      $(this).ekkoLightbox();
    });
  }

  render() {
    const { roomsText, gardenText, roomsImages, gardenImages } = this.props;

    return (
      <article className="rooms">
        <div className="rooms__textContent">
          <MarkdownContent content={roomsText} />
        </div>

        <div className="rooms__gallery row text-center text-lg-left">
          {roomsImages.map((image, index) => (
            <Image key={index} {...image} />
          ))}
        </div>

        <div className="rooms__textContent">
          <MarkdownContent content={gardenText} />
        </div>

        <div className="rooms__gallery row text-center text-lg-left">
          {gardenImages.map((image, index) => (
            <Image key={index} {...image} />
          ))}
        </div>
      </article>
    );
  }
}

export default ({ data, location }) => {
  const { markdownRemark: page, allMarkdownRemark, site } = data;

  const images = get(allMarkdownRemark, 'edges', []).map(
    i => i.node.frontmatter,
  );

  console.log({ images });

  return (
    <Layout location={location}>
      <Helmet
        title={`${site.siteMetadata.title} | ${page.frontmatter.title}`}
      />

      <RoomsPageTemplate
        roomsText={page.frontmatter.rooms}
        gardenText={page.frontmatter.garden}
        roomsImages={images.filter(i => i.type === 'roomImage')}
        gardenImages={images.filter(i => i.type === 'gardenImage')}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query RoomsPageQuery($path: String!) {
    markdownRemark(frontmatter: { urlPath: { eq: $path } }) {
      frontmatter {
        title
        rooms
        garden
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { type: { in: ["roomImage", "gardenImage"] } } }
    ) {
      edges {
        node {
          frontmatter {
            image {
              publicURL
              childImageSharp {
                fixed(width: 255) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            title
            description
            type
          }
        }
      }
    }
  }
`;
